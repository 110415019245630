import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Transition as _Transition } from "vue"
import _imports_0 from '@/assets/images/fastbike-logo.svg'


const _hoisted_1 = { class: "mobile-menu md:hidden" }
const _hoisted_2 = { class: "mobile-menu-bar" }
const _hoisted_3 = ["alt"]
const _hoisted_4 = { class: "block text-white text-base ml-3" }
const _hoisted_5 = {
  key: 0,
  class: "border-t border-theme-24 py-5"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "menu__icon" }
const _hoisted_8 = { class: "menu__title" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "menu__icon" }
const _hoisted_12 = { class: "menu__title" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "menu__icon" }
const _hoisted_16 = { class: "menu__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BarChart2Icon = _resolveComponent("BarChart2Icon")!
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!
  const _component_ActivityIcon = _resolveComponent("ActivityIcon")!
  const _component_ZapIcon = _resolveComponent("ZapIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/",
        tag: "a",
        class: "flex mr-auto"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            alt: _ctx.adminTitle,
            class: "w-6",
            src: _imports_0
          }, null, 8, _hoisted_3),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.adminTitle), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_BarChart2Icon, {
        class: "w-8 h-8 text-white transform -rotate-90",
        onClick: _ctx.toggleMobileMenu
      }, null, 8, ["onClick"])
    ]),
    _createVNode(_Transition, {
      onEnter: _ctx.enter,
      onLeave: _ctx.leave
    }, {
      default: _withCtx(() => [
        (_ctx.activeMobileMenu)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formattedMenu, (menu, menuKey) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (menu == 'devider')
                    ? (_openBlock(), _createElementBlock("li", {
                        key: menu + menuKey,
                        class: "menu__devider my-6"
                      }))
                    : (_openBlock(), _createElementBlock("li", {
                        key: menu + menuKey
                      }, [
                        _createElementVNode("a", {
                          href: "javascript:;",
                          class: _normalizeClass(["menu", {
                'menu--active': menu.active,
                'menu--open': menu.activeDropdown,
              }]),
                          onClick: ($event: any) => (_ctx.linkTo(menu, _ctx.router))
                        }, [
                          _createElementVNode("div", _hoisted_7, [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(menu.icon)))
                          ]),
                          _createElementVNode("div", _hoisted_8, [
                            _createTextVNode(_toDisplayString(menu.title) + " ", 1),
                            (menu.subNodes)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: _normalizeClass(["menu__sub-icon", { 'transform rotate-180': menu.activeDropdown }])
                                }, [
                                  _createVNode(_component_ChevronDownIcon)
                                ], 2))
                              : _createCommentVNode("", true)
                          ])
                        ], 10, _hoisted_6),
                        _createVNode(_Transition, {
                          onEnter: _ctx.enter,
                          onLeave: _ctx.leave
                        }, {
                          default: _withCtx(() => [
                            (menu.subNodes && menu.activeDropdown)
                              ? (_openBlock(), _createElementBlock("ul", _hoisted_9, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menu.subNodes, (subMenu, subMenuKey) => {
                                    return (_openBlock(), _createElementBlock("li", { key: subMenuKey }, [
                                      _createElementVNode("a", {
                                        href: "javascript:;",
                                        class: _normalizeClass(["menu", { 'menu--active': subMenu.active }]),
                                        onClick: ($event: any) => (_ctx.linkTo(subMenu, _ctx.router))
                                      }, [
                                        _createElementVNode("div", _hoisted_11, [
                                          (subMenu.icon)
                                            ? (_openBlock(), _createBlock(_resolveDynamicComponent(subMenu.icon), { key: 0 }))
                                            : (_openBlock(), _createBlock(_component_ActivityIcon, { key: 1 }))
                                        ]),
                                        _createElementVNode("div", _hoisted_12, [
                                          _createTextVNode(_toDisplayString(subMenu.title) + " ", 1),
                                          (subMenu.subNodes)
                                            ? (_openBlock(), _createElementBlock("div", {
                                                key: 0,
                                                class: _normalizeClass(["menu__sub-icon", {
                          'transform rotate-180': subMenu.activeDropdown,
                        }])
                                              }, [
                                                _createVNode(_component_ChevronDownIcon)
                                              ], 2))
                                            : _createCommentVNode("", true)
                                        ])
                                      ], 10, _hoisted_10),
                                      _createVNode(_Transition, {
                                        onEnter: _ctx.enter,
                                        onLeave: _ctx.leave
                                      }, {
                                        default: _withCtx(() => [
                                          (subMenu.subNodes && subMenu.activeDropdown)
                                            ? (_openBlock(), _createElementBlock("ul", _hoisted_13, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subMenu.subNodes, (lastSubMenu, lastSubMenuKey) => {
                                                  return (_openBlock(), _createElementBlock("li", { key: lastSubMenuKey }, [
                                                    _createElementVNode("a", {
                                                      href: "javascript:;",
                                                      class: _normalizeClass(["menu", { 'menu--active': lastSubMenu.active }]),
                                                      onClick: ($event: any) => (_ctx.linkTo(lastSubMenu, _ctx.router))
                                                    }, [
                                                      _createElementVNode("div", _hoisted_15, [
                                                        _createVNode(_component_ZapIcon)
                                                      ]),
                                                      _createElementVNode("div", _hoisted_16, _toDisplayString(lastSubMenu.title), 1)
                                                    ], 10, _hoisted_14)
                                                  ]))
                                                }), 128))
                                              ]))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 2
                                      }, 1032, ["onEnter", "onLeave"])
                                    ]))
                                  }), 128))
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["onEnter", "onLeave"])
                      ]))
                ], 64))
              }), 256))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["onEnter", "onLeave"])
  ]))
}